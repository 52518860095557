import styled from 'styled-components';
import { breakpoint, container } from 'styles';

export const StyledBottomFooter = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column-reverse;
  align-items: center;

  ${breakpoint.md} {
    flex-direction: row;
    justify-content: space-between;
  }

  ${container}
`;

export const StyledLinkFooter = styled.div`
  display: flex;
  gap: var(--gap-xs);
  margin-bottom: 32px;
  text-decoration: underline;

  ${breakpoint.md} {
    margin-bottom: 0;
  }
`;
