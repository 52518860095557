import React, { FC } from 'react';
import { renderRichText } from 'gatsby-source-contentful/rich-text';

import Link from 'components/atoms/Link';
import Typography from 'components/atoms/Typography';

import { StyledBottomFooter, StyledLinkFooter } from './BottomFooter.styles';

import { BottomFooterProps } from './models.d';

const BottomFooter: FC<BottomFooterProps> = ({ testId, copyright, link: links }) => (
  <StyledBottomFooter data-testid="bottom-footer">
    <Typography testId={testId} color="gray-400" size={16}>
      {renderRichText(copyright)}
    </Typography>
    <StyledLinkFooter>
      {links.map((link) => (
        <Link typography={{ size: 16, fontWeight: 500, color: 'gray-25' }} key={link.id} to={link}>
          {link.label}
        </Link>
      ))}
    </StyledLinkFooter>
  </StyledBottomFooter>
);

export default BottomFooter;
